<template>
    <div id="login" class="container-fluid flex-center" style="flex-direction: column; align-content: space-between;">
        <section class="login mt-auto mb-auto">

            <section class="login__logo d-flex justify-content-center align-items-center mb-3">
                <div style="max-width: 344px; margin-left: 15px;">
                    <img :src="img_logo" class="img-fluid">    
                </div>
            </section>
            
            <section class="login__content bg-secondary-dark px-3 py-4 mb-3">
                <div style="max-width: 344px;">
                    <form class="row">
                        <div class="col-12 mb-4">
                            <li-input v-model="username" _id="username"
                                label="Nombre de usuario" 
                                placeholder="Ingresa tu nombre de usuario" 
                                type="text" 
                                name="username" 
                                autocomplete
                                full>
                            </li-input>
                            <span class="text-danger" v-if="errors.username">
                                {{ errors.username}}
                            </span>
                        </div>

                        <div class="col-12 mb-3">
                            <div class="mb-1">
                                <span class="text-white">
                                    Contraseña
                                </span>
                            </div>

                            <input v-model="password" id="input_password"
                                @keydown.enter="login"
                                autocomplete="off"
                                placeholder="Ingresa tu contraseña" 
                                :type="local_type" 
                                name="password" 
                                class="input-thm-dark full-width">

                            <img :src="(local_type == 'password') ? icon_eye : icon_eye_slash" 
                                @click="showPassword()" 
                                class="cursor-pointer"
                                :style="`${(local_type == 'password') ? 'margin-left: -30px; width: 18px;' : 'margin-left: -31px; width: 20px;' }`">
                            
                            <span class="text-danger" v-if="errors.password">
                                {{ errors.password}}
                            </span>
                        </div>


                        <div class="col-12 text-right mb-3">
                            <span class="text-white">
                                ¿Olvidaste tu contraseña?
                            </span>
                        </div>

                        <div class="col-12 mb-2">
                            <button @click.stop="login" class="btn btn-danger btn-pill full-width"
                                :disabled="is_login_process"
                                type="button">
                                Iniciar sesión
                            </button>
                        </div>

                        <div class="col-12 my-2">
                            <a href="#">
                                <button class="btn btn-secondary btn-pill full-width">
                                    Registrarse
                                </button>
                            </a>
                        </div>


                    </form>
                </div>
            </section>
        </section>

        <section class="mb-3">
            2023. Mono Cilindrero ® Todos los derechos reservados.
        </section>
    </div>
</template>

<script>
    // Store
    import { mapGetters } from 'vuex'

    // COMPONENTS
    
    // VIDEOS
    
    // IMAGES
    import img_logo from 'ASSETS/images/logo'
    import icon_eye from 'ASSETS/icons/eye-icon'
    import icon_eye_slash from 'ASSETS/icons/eye-slash-icon'

    export default {
        name: 'login',
        components: {

        },
        data() {
            return {
                img_logo,
                icon_eye,
                icon_eye_slash,
                is_login_process: false,
                username: null,
                password: null,
                //phone: '3344444444',
                //password: 'emi240406'
                local_type: 'password',
                errors: {
                    login: null,
                    password: null
                }
            }
        },
        computed: {
            ...mapGetters([
                'getSession',
                'getLogin'
            ]),

            current_route_name() {
                return this.$route.name
            },
            
        },
        methods: {
            showPassword() {
                this.local_type = (this.local_type === 'password') ? 'text' : 'password'
            },
            redirect(name_route) {
                if(this.$route.name !== name_route) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: name_route
                    })
                }
            },
            async login() {

                this.is_login_process = true;
                let complete = true

                for(let error in this.errors) {
                    this.errors[error] = null
                }

                if(_.isEmpty(this.username) || _.isNull(this.username)) {
                    this.errors['username'] = '*Campo obligatorio'
                    complete = false
                }

                if(_.isEmpty(this.password) || _.isNull(this.password)) {
                    this.errors['password'] = '*Campo obligatorio'
                    complete = false
                }

                
                if(complete) {
                    let payload = {
                        username: this.username,
                        password: this.password
                    }

                    await this.$store.dispatch('login', payload)
                    
                    if(!this.getSession) {
                        this.is_login_process = false
                    }
                    else if(this.getSession.id_rol == 1) {
                        this.redirect('dashboard');
                        console.log('here')
                    }

                    else if(this.getSession.id_rol !== 1) {
                        this.redirect('units');
                        console.log('here 2')
                    }
                    
                }

                else {
                    this.is_login_process = false
                }

                
            }
        },
        created() {
            
        }
    }
</script>

<style lang="scss">
    #login {
        min-height: 100vh;
        background: rgba(33,37,41,1);
        background: linear-gradient(180deg, rgba(35,48,53,1) 0%, rgba(33,37,41,1) 40%);

        .login {

            &__logo {

            }

            &__content {
                border-radius: 12px;
                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.69));
            }
        }
    }
</style>